import type { $Fetch } from 'nitropack';

import type { Category, CategoryDisplayIn } from '~/models';

import type { DefaultFilter } from './filter';

interface Filter extends Partial<DefaultFilter> {
  displayIn?: CategoryDisplayIn;
  onlyMains?: boolean;
  availableNow?: boolean;
}

export default class CategoryService {
  constructor(private fetch: $Fetch) {}

  async list(filter?: Filter): Promise<Category[] | null> {
    return await this.fetch<Category[]>('/categories/', {
      query: filter
    });
  }
}
