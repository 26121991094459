import { onRequestError, onResponseError } from '~/utils/service';

import AddonService from '~/services/addon';
import AddressService from '~/services/address';
import AuthService from '~/services/auth';
import BookingService from '~/services/booking';
import CartService from '~/services/cart';
import CategoryService from '~/services/category';
import CompanyService from '~/services/company';
import DiscountService from '~/services/discount';
import GoogleMapsService from '~/services/google.maps';
import OrderService from '~/services/order';
import PreferenceSerice from '~/services/preference';
import ProductService from '~/services/product';
import ProfileService from '~/services/profile';
import StripeService from '~/services/stripe';

interface HttpServices {
  address: AddressService;
  addon: AddonService;
  auth: AuthService;
  booking: BookingService;
  cart: CartService;
  category: CategoryService;
  company: CompanyService;
  discount: DiscountService;
  google: {
    maps: GoogleMapsService;
  };
  order: OrderService;
  preference: PreferenceSerice;
  product: ProductService;
  profile: ProfileService;
  stripe: StripeService;
}

export default defineNuxtPlugin(() => {
  const fetcher = $fetch.create({
    baseURL: useBaseURL(),
    onRequest({ options }) {
      options.headers = handleHeaders(options.headers);
      options.query = handleQuery(options.query);
    },
    onRequestError,
    onResponseError
  });

  const http: HttpServices = {
    address: new AddressService(fetcher),
    addon: new AddonService(fetcher),
    auth: new AuthService(fetcher),
    booking: new BookingService(fetcher),
    cart: new CartService(fetcher),
    category: new CategoryService(fetcher),
    company: new CompanyService(fetcher),
    discount: new DiscountService(fetcher),
    google: {
      maps: new GoogleMapsService()
    },
    order: new OrderService(fetcher),
    preference: new PreferenceSerice(fetcher),
    product: new ProductService(fetcher),
    profile: new ProfileService(fetcher),
    stripe: new StripeService(fetcher)
  };

  return {
    provide: {
      http
    }
  };
});
