import validate from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.3_@unocss+reset@_2alybkswdq6hpv2nvru7c76o5e/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.3_@unocss+reset@_2alybkswdq6hpv2nvru7c76o5e/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "account-desktop": () => import("/app/middleware/account-desktop.ts"),
  "account-mobile": () => import("/app/middleware/account-mobile.ts"),
  "auth-and-menu": () => import("/app/middleware/auth-and-menu.ts"),
  "auth-or-guest": () => import("/app/middleware/auth-or-guest.ts"),
  "auth-or-home": () => import("/app/middleware/auth-or-home.ts"),
  checkout: () => import("/app/middleware/checkout.ts"),
  "confirm-email": () => import("/app/middleware/confirm-email.ts")
}