<script lang="ts" setup>
const emits = defineEmits<{
  (e: 'refresh'): void;
}>();

const onRefresh = (): void => {
  emits('refresh');
};
</script>

<template>
  <div class="h-full w-full flex flex-col items-center justify-center text-gray-900">
    <span class="text-3xl font-bold leading-10">
      Failed to connect to the server
    </span>

    <h1 class="text-xl font-bold leading-8">
      Await some time and try refresh page
    </h1>

    <div class="flex justify-center gap-2 py-4">
      <UButton
        size="xl"
        icon="i-ic-round-refresh"
        label="Refresh"
        @click="onRefresh"
      />
    </div>
  </div>
</template>
